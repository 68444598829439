import Section, { SECTION_VARIANTS } from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Grid, Box } from '@achieve/ascend'
import SlideOnVisible from 'components/SlideOnVisible/SlideOnVisible'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'

import classNames from 'classnames'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import { IconCheckMark } from 'components/Svgs/IconCheckMark'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

function ARCloserModule({
  config,
  title,
  eyebrow,
  itemList,
  button,
  mainImage,
  secondaryImage,
  badge,
  appStoreButtonFlag,
  appleButton,
  googleButton,
  appleImage,
  googleImage,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isSmallDesktop = useViewportSmallerThan(BREAKPOINTS.xl)
  const isXS = useViewportSmallerThan(BREAKPOINTS.sm)
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const badgeProps = {
    width: 90,
    height: 90,
    anchor: ANCHOR_POINTS.RIGHT_TOP,
    translateX: isSmallDesktop ? -230 : -320,
    translateY: -100,
  }

  const badgePropsMobile = {
    width: 80,
    height: 80,
    anchor: ANCHOR_POINTS.RIGHT_TOP,
    translateX: isXS ? -40 : 50,
    translateY: -10,
  }

  return (
    <Section
      contain={false}
      variant={config?.jsonContent?.sectionVariant ?? SECTION_VARIANTS.neutralGrey8}
      className={'py-12 px-[10px] overflow-hidden lg:py-12 lg:px-8'}
      data-testid="ARCloser_section"
    >
      <Grid
        container
        className={classNames(
          'items-center justify-center [background:linear-gradient(185deg,#30f_4.22%,#586afc_71.44%,#b1d6ff_127.36%)] shadow-[0_2px_10px_0_rgb(0_0_0_/_10%)] text-white max-w-[600px] m-auto px-4 py-0 rounded-[10px] lg:[justify-content:inherit] lg:max-w-[1216px] lg:p-8',
          (isMobile && badge && 'mt-12') ?? ''
        )}
        direction="row"
      >
        {!isMobile && (
          <Grid
            item
            xxs={12}
            lg={6}
            className={
              'max-w-[500px] pt-12 pb-0 px-0 lg:max-w-[initial] lg:p-0 lg:min-h-[368px] [&>.pd-item-brand]:lg:pr-[50px] [&>.pd-item-brand]:xl:px-0'
            }
          >
            <Box
              className={'pd-item-brand flex flex-col max-w-[390px] relative mx-auto my-0 gap-0'}
            >
              {mainImage && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div>
                    <MediaImageStandard
                      alt={mainImage?.mediaAltText || mainImage?.fieldName}
                      content={mainImage?.mediaContent}
                      className={'rounded-md'}
                      width="290"
                      height="368"
                      layout="fixed"
                      data-testid="ar-closer-main-image"
                    />
                  </div>
                </SlideOnVisible>
              )}
              <Box
                className={
                  'relative mt-[-70px] lg:mt-[-183px] lg:w-[130%] lg:right-[-90px] xl:right-[-113px]'
                }
              >
                {badge && (
                  <SlideOnVisible
                    duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                    timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                    easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                  >
                    <div>
                      <AchieveBadge
                        content={badge?.mediaContent}
                        {...badgeProps}
                        data-testid="ar-closer-badge"
                      />
                    </div>
                  </SlideOnVisible>
                )}
                {secondaryImage && (
                  <SlideOnVisible
                    duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                    timeout={350}
                    easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                  >
                    <div className={'max-w-[388px] [&_img]:h-auto'}>
                      <MediaImageStandard
                        content={secondaryImage?.mediaContent}
                        className={''}
                        data-testid="ar-closer-sub-image"
                        alt={secondaryImage?.mediaAltText || secondaryImage?.fieldName}
                      />
                    </div>
                  </SlideOnVisible>
                )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          lg={6}
          className={'max-w-[500px] pt-0 pb-12 px-0 lg:max-w-[initial] lg:p-0'}
        >
          <SlideOnVisible
            duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <Box
              className={
                'flex flex-col gap-[23px] relative mx-auto my-0 max-w-[274px] pt-12 lg:max-w-[315px] lg:pt-0'
              }
            >
              {isMobile && badge && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div>
                    <AchieveBadge content={badge?.mediaContent} {...badgePropsMobile} />
                  </div>
                </SlideOnVisible>
              )}
              {eyebrow && (
                <Eyebrow
                  className={'text-center text-white pb-0 lg:text-left'}
                  content={eyebrow?.textContent}
                  data-testid="pd-item-eyebrow"
                />
              )}
              {title && (
                <Typography
                  className={classNames('section-title', 'text-center lg:text-left')}
                  content={title?.textContent}
                  fontWeight="medium"
                  data-testid="pd-item-title"
                />
              )}

              {isMobile && secondaryImage && (
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={350}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div className={'[&_img]:h-auto'}>
                    <MediaImageStandard
                      content={secondaryImage?.mobileMediaContent ?? secondaryImage?.mediaContent}
                      alt={secondaryImage?.mediaAltText || secondaryImage?.fieldName}
                      className={''}
                    />
                  </div>
                </SlideOnVisible>
              )}

              {itemList?.gridItemsCollection?.items?.length > 0 && (
                <ul
                  className={'list-none m-0 p-0 [&_li]:pb-2 [&_li]:[&_div]:flex-nowrap [&_p]:pl-2'}
                >
                  {itemList?.gridItemsCollection?.items.map((icon, index) => {
                    return (
                      <li key={`item-${index}`} className={''}>
                        <Grid className={''} container direction="row">
                          <div>
                            <IconCheckMark
                              title={`${
                                mainProductConfig[siteEnv || 'achieve']?.['MainName']
                              } checkmark icon`}
                              height={24}
                              width={24}
                            />
                          </div>
                          <div>
                            <Typography
                              content={icon?.textContent}
                              data-testid="ar-icon-list-item"
                              variant="bodySm"
                            />
                          </div>
                        </Grid>
                      </li>
                    )
                  })}
                </ul>
              )}

              {appStoreButtonFlag ? (
                <AppStoreButtons
                  appleButton={appleButton}
                  googleButton={googleButton}
                  appleImage={appleImage}
                  googleImage={googleImage}
                />
              ) : (
                button && (
                  <Box>
                    <LinkButton
                      track={{
                        ...{
                          click_id:
                            button?.linkText?.json?.content[0]?.content[0]?.value || 'no-click-id',
                          nav_link_section: 'Closer CTA',
                          list_name: 'Home Hero CTA click',
                          click_text: button?.linkText?.json?.content[0]?.content[0]?.value,
                          track_event: 'internal_campaign_click',
                          event_action: 'button_click',
                          event_type: 'button_click',
                        },
                        ...button?.event,
                      }}
                      typographicOptions={{
                        variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                        fontWeight: 'bold',
                      }}
                      variant="contained"
                      color="secondary"
                      content={button}
                      className={''}
                      data-testid="ar-closer-cta"
                    />
                  </Box>
                )
              )}
            </Box>
          </SlideOnVisible>
        </Grid>
      </Grid>
    </Section>
  )
}

function ARCloser({ content }) {
  let contentToGet = [
    'config',
    'title',
    'eyebrow',
    'itemList',
    'button',
    'mainImage',
    'secondaryImage',
    'badge',
  ]

  if (content?.fields?.additionalConfiguration?.jsonContent?.appStoreButtons)
    contentToGet = [...contentToGet, 'image_apple', 'image_google', 'button_apple', 'button_google']

  const {
    config,
    title,
    eyebrow,
    itemList,
    button,
    mainImage,
    secondaryImage,
    badge,
    image_apple,
    image_google,
    button_apple,
    button_google,
  } = useMemoizedContentGetter(content, contentToGet)

  return (
    <ARCloserModule
      config={config}
      title={title}
      eyebrow={eyebrow}
      itemList={itemList}
      button={button}
      mainImage={mainImage}
      secondaryImage={secondaryImage}
      badge={badge}
      appStoreButtonFlag={content?.fields?.additionalConfiguration?.jsonContent?.appStoreButtons}
      appleImage={image_apple}
      googleImage={image_google}
      appleButton={button_apple}
      googleButton={button_google}
    />
  )
}

export { ARCloser, ARCloserModule }
export default ARCloser
